import type { ProductTileProps } from '@design-system/components/UI/UIProductTile.props'

export const useShopTheLook = () => {
  const { skuPrices, fetchSkuPrices } = useProductTileClPrices()
  const { normalizeProduct } = useWrapperProductTile()

  const getProducts = async (products: string[]) => {
    const algoliaIndex = useAppConfig().currentAlgoliaIndex
    const algoliaProducts = products.length
      ? await $fetch(`/api/getProducts/${algoliaIndex}`, {
          query: {
            mfcList: products.join(','),
          },
        })
      : []

    const filteredAlgoliaProducts = algoliaProducts.filter(isNonNullable)

    const skuCodes = filteredAlgoliaProducts
      ?.map(algoliaProduct => algoliaProduct?.size?.[0]?.SKU)
      ?.filter((skuCode): skuCode is string => !!skuCode)

    await fetchSkuPrices(skuCodes)

    const productsDetails = filteredAlgoliaProducts
      .map(product => {
        const skuWithSize = product?.size?.[0].SKU

        const productWithPrices = {
          ...product,
          ...(skuWithSize ? { clPrices: skuPrices.value?.[skuWithSize] } : {}),
        }

        const normalizedProduct = normalizeProduct(productWithPrices)

        if (!normalizedProduct) return

        const productTileProps: ProductTileProps = {
          product: normalizedProduct,
          onlyImg: false,
          previewSlide: 0,
        }

        return productTileProps
      })
      .filter(p => p !== undefined)

    return productsDetails
  }

  return { getProducts }
}
