import type { ProductCardHorizontalProps } from '@design-system/components/UI/UIProductCardHorizontal.props'
import type { ProductTileProps } from '@design-system/components/UI/UIProductTile.props'
import type { ProductSchema } from 'integration-layer/.nuxt/armani/product-schema'
import type { ProductTileType } from '~/modules/05.productServices/runtime/components/WrapperProductTile.props'

export const useShopTheLook = () => {
  const { skuPrices, fetchSkuPrices } = useProductTileClPrices()
  const { normalizeProduct, transformToProductCardHorizontal } =
    useWrapperProductTile()
  const { recommendations } = useXgen()

  const getProducts = async (xgenIdOrAlgoliaIds: string | string[]) => {
    let algoliaProducts: ProductSchema[]

    if (typeof xgenIdOrAlgoliaIds === 'string') {
      algoliaProducts = await recommendations({
        predictionID: xgenIdOrAlgoliaIds,
      })
    } else if (Array.isArray(xgenIdOrAlgoliaIds) && xgenIdOrAlgoliaIds.length) {
      const algoliaIndex = useAppConfig().currentAlgoliaIndex
      algoliaProducts = await $fetch(`/api/getProducts/${algoliaIndex}`, {
        query: {
          mfcList: xgenIdOrAlgoliaIds.join(','),
        },
      })
    } else {
      return []
    }

    const filteredAlgoliaProducts = algoliaProducts.filter(isNonNullable)

    const skuCodes = filteredAlgoliaProducts
      ?.map(algoliaProduct => algoliaProduct?.size?.[0]?.SKU)
      ?.filter((skuCode): skuCode is string => !!skuCode)

    await fetchSkuPrices(skuCodes)

    const productsDetails = filteredAlgoliaProducts
      .map(product => {
        const skuWithSize = product?.size?.[0].SKU

        const productWithPrices = {
          ...product,
          ...(skuWithSize ? { clPrices: skuPrices.value?.[skuWithSize] } : {}),
        }

        const normalizedProduct = normalizeProduct(productWithPrices)

        if (!normalizedProduct) return

        const productTileProps: ProductTileProps = {
          product: normalizedProduct,
          onlyImg: false,
          previewSlide: 0,
        }

        return productTileProps
      })
      .filter(p => p !== undefined)

    return productsDetails
  }

  const transformProductForQuickBuy = (
    product: ProductTileType
  ): ProductCardHorizontalProps => {
    return transformToProductCardHorizontal(product)
  }
  return { getProducts, transformProductForQuickBuy }
}
